import React from 'react'
import styled from 'styled-components'
import MainTemplate from "templates/MainTemplate"
import Logo from "components/Logo"
import { Link } from 'gatsby';

const Wrapper = styled.div`
  padding: 15px;
  width: 100vw;
  text-align: center;
`;

const H1 = styled.h1`
  padding-top: 10%;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.family.serif};
  font-size: ${({ theme }) => theme.font.size.xl};
`;

const H2 = styled.h2`
font-size: ${({ theme }) => theme.font.size.l};
font-weight: 700;
`;



export default () => (
  <MainTemplate>
    <Wrapper>
      <Logo />
      <H1>404</H1>
      <H2>Hi 👋, you might get lost!</H2>
      <Link to="/main">Return to main page.</Link>
    </Wrapper>
  </MainTemplate>
)
